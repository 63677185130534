import * as React from "react"
import { navigate, Link } from "gatsby"

import { BaseGameStartLayout, OtherGamesList } from "@monorepo/shared"
import { hitler } from "@monorepo/games/hitler"

const Game = () => (
  <BaseGameStartLayout
    game={hitler}
    language="fi"
    onStart={() => navigate("/game")}
  >
    <OtherGamesList
      title="Testaa näitä mahtavia pelejä"
      games={[
        {
          url: "https://neverhaveiever.drinkinggame.app/fi",
          icon: "https://neverhaveiever.drinkinggame.app/icons/icon-48x48.png",
          name: "En ole koskaan",
          description:
            'Klassikko "Never Have I Ever" nyt puhelinpelinä +50 kysymystä!',
        },
        {
          url: "https://mostlikelyto.drinkinggame.app/fi",
          icon: "https://mostlikelyto.drinkinggame.app/icons/icon-48x48.png",
          name: "Osoita kuka",
          description: "Osoita kuka on hauska peli tiiviille kaveriporukalle!",
        },
      ]}
      LinkComponent={(url, children) => <Link to={url}>{children}</Link>}
    />
  </BaseGameStartLayout>
)

export default Game
